export function buildContentfullImageUrl(assetUrl, transformations) {
  if (transformations) {
    return 'https:' + assetUrl + "?" + transformations + "&fm=webp" + "&q=75";
  } else {
    return 'https:' + assetUrl + "?fm=webp" + "&q=75";
  }
}

export function buildContentfullImageUrlByHeight(assetUrl, height = 'auto', transformations = 'c_limit', format = 'auto') {
  return 'https:' + assetUrl + "?h=" + height + "&fm=webp" + "&q=75";
}

export function buildContentfullImageUrlByWidth(assetUrl, width = 'auto', transformations = 'c_limit', format = 'auto') {
  return 'https:' + assetUrl + "?fm=webp" + "&q=75";
}

export function imageContentfullSrc(assetUrl, transformations) {
  return buildContentfullImageUrl(assetUrl, transformations);
}

export function imageContentfullDpr(assetUrl, transformations = '') {
  if (!transformations) {
    return buildContentfullImageUrl(assetUrl);
  }

  return buildContentfullImageUrl(assetUrl, transformations)
}

export default {
  buildContentfullImageUrl,
  buildContentfullImageUrlByHeight,
  buildContentfullImageUrlByWidth,
  imageContentfullDpr,
  imageContentfullSrc,
};
